import { useEffect, useState } from "react";
import CandidateGuarantor from "../../models/CandidateGuarantor";
import candidateService from "../../services/CandidateService";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../theme/GlobalTheme";
import SponsorFileModal from "./GuarantorFileModal";
import utilsService from "../../services/UtilsService";
import { GenericItem } from "../../models/GenericItem";
import GuarantorModal from "./GuarantorModal";
import Province from "../../models/Province";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CandidateGuarantorStatus from "../../models/CandidateGuarantorStatus";
import { useAppSelector } from "../../store/store";
import { ApplicationState } from "../../models/ApplicationState";

function CandidateGuarantorScreen() {
  const [guarantors, setGuarantors] = useState<CandidateGuarantor[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
  const [candidateSponsor, setCandidateSponsor] =
    useState<CandidateGuarantor>();
  const [documentTypes, setDocumentTypes] = useState<GenericItem[]>([]);
  const [sponsorModal, setSponsorModal] = useState<boolean>(false);
  const [province, setProvince] = useState<Province[]>([]);
  const [status, setStatus] = useState<CandidateGuarantorStatus>();

  async function initialData() {
    const guarantor = await candidateService.getCandidateGuarantors();
    const generic = await utilsService.getGenericItems("GUARANTOR_DOC");
    const pro = await utilsService.getProvinces();
    const status = await candidateService.getCandidateGuarantorStatus();

    setStatus(status);
    setProvince(pro);
    setDocumentTypes(generic);
    setGuarantors(guarantor);
    setIsLoading(false);
  }
  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          display="inline-block"
          sx={{
            mb: 3,
            backgroundColor: "#00166B",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
            textTransform: "uppercase",
          }}
          variant="h4"
        >
          Garantes
        </Typography>
      </Box>
      {/* <Typography sx={{ mb: 3 }} variant="h3">
        Garantes
      </Typography> */}
      {isLoading ? (
        <Box textAlign="center">
          <Box display="inline-block" textAlign="center">
            <CircularProgress color="secondary" size={50} sx={{ mr: "10px" }} />
            <Typography variant="body1">Cargando...</Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Grid sx={{ mb: 2 }} item xs={12}>
            <Alert style={{ fontSize: 18 }} severity="warning">
              Te recordamos que debes tener tu garantía al 100% aprobada y
              firmada para fines de poder agendar tu cita consular.
            </Alert>
          </Grid>
          <Grid mb={2} container rowSpacing={2} columnSpacing={5}>
            <Grid item lg={6} xs={12}>
              <Box
                color="white"
                borderRadius={2}
                height={110}
                sx={{ backgroundColor: "#26C6DB" }}
              >
                <Box p={2} display="flex" justifyContent="space-between">
                  <PersonIcon sx={{ fontSize: "70px" }} />
                  <Box
                    textAlign="right"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Typography sx={{ fontSize: "22px" }}>
                      {status?.guarantorStatus.toUpperCase()}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }}>
                      Estatus De La Garantia
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box
                borderRadius={2}
                height={110}
                sx={{ backgroundColor: "#66bb6a" }}
              >
                <Box
                  color="white"
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <CheckCircleIcon sx={{ fontSize: "70px" }} />
                  <Box
                    textAlign="right"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Typography sx={{ fontSize: "30px" }}>
                      {" "}
                      {status?.guarantorPercentage}%
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }}>
                      Porcentaje Aprobado
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {status?.guarantorPercentage != undefined &&
          status?.guarantorPercentage < 100 ? (
            <Button
              variant="contained"
              sx={{ mb: 2 }}
              onClick={() => {
                setSponsorModal(true);
              }}
            >
              Agregar Garante
            </Button>
          ) : null}

          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Nombre</StyledTableCell>
                  <StyledTableCell align="left">Apellido</StyledTableCell>
                  <StyledTableCell align="left">Porcentaje</StyledTableCell>
                  <StyledTableCell align="left">
                    Archivos Enviados
                  </StyledTableCell>
                  <StyledTableCell width={300} align="left">
                    Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {guarantors.map((el, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="left">
                      {el.guarantorName}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.guarantorLastName}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.guarantorPercent}%
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {el.filesURL.split(",").map((ele, index) => {
                        if (ele != "") {
                          return (
                            <Link
                              key={index}
                              target="_blank"
                              sx={{ display: "block", mb: 0.6 }}
                              href={ele.split("|")[0].trim()}
                              underline="hover"
                            >
                              - {ele.split("|")[1]}
                            </Link>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <Button
                        onClick={() => {
                          setCandidateSponsor(el);
                          setFileUploadModal(true);
                        }}
                        sx={{ fontSize: "10px" }}
                        variant="contained"
                      >
                        Subir Documento
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {el.guarantorStatus}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Dialog fullWidth={true} maxWidth="md" open={fileUploadModal}>
        <DialogTitle>Agregar Documento</DialogTitle>
        <DialogContent>
          <SponsorFileModal
            onSave={async () => {
              const guarantor = await candidateService.getCandidateGuarantors();
              setGuarantors(guarantor);
              setFileUploadModal(false);
            }}
            documentTypes={documentTypes}
            candidateSponsor={candidateSponsor!}
            onClose={() => {
              setFileUploadModal(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog fullWidth={true} maxWidth="md" open={sponsorModal}>
        <DialogTitle>Agregar Garante</DialogTitle>
        <DialogContent>
          <GuarantorModal
            onSave={async () => {
              const guarantor = await candidateService.getCandidateGuarantors();
              setGuarantors(guarantor);
              setSponsorModal(false);
            }}
            onClose={() => {
              setSponsorModal(false);
            }}
            provinces={province}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default CandidateGuarantorScreen;
